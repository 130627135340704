const form = document.getElementById('signupForm')
const url = 'https://olbi2t3rr4.execute-api.us-west-2.amazonaws.com/dev/inquiry'
const toast = document.getElementById('toast')
const submit = document.getElementById('submit')

function post(url, body, callback) {
  var req = new XMLHttpRequest();
  req.open("POST", url, true);
  req.setRequestHeader("Content-Type", "application/json");
  req.addEventListener("load", function () {
    if (req.status < 400) {
      callback(null, JSON.parse(req.responseText));

    } else {
      callback(new Error("Request failed: " + req.statusText));
    }
  });
  req.send(JSON.stringify(body));
}

function success () {
  toast.innerHTML = 'Thanks for sending us a message! I\'ll get in touch with you shortly...'
  submit.disabled = false
  submit.blur()
  form.reset()
}

function error (err) {
  toast.innerHTML = 'There was an error sending your message, Please try again'
  sumbit.disabled = false
  console.log(err)
}

form.addEventListener('submit', function (e) {
  e.preventDefault()
  toast.innerHTML = 'Sending'
  submit.disabled = true

  const payload = {
    firstname: form.firstname.value,
    lastname: form.lastname.value,
    phonenumber: form.phonenumber.value,
    tobehere: form.tobehere.value,
    email: form.email.value,
    about: form.about.value,
    residence: form.residence.value,
    // typeofcare: form.typeofcare.value,
    referrer: form.referrer.value,
  }
  post(url, payload, function (err, res) {
    if (err) { return error(err) }
    success()
  })
})
